<template>
  <nav :aria-label="$t.utilityNavigationLabel">
    <base-link v-if="auth.loggedIn" to="/account" class="duration hover:c-grey-60" @click="handleClick($t.myAccount)">
      {{ $t.myAccount }}
    </base-link>
    <a
      v-else
      :href="useLocalisedRoute('/sign-in')"
      role="button"
      class="duration hover:c-grey-60"
      @click.prevent="handleSignInClick"
    >
      {{ $t.signIn }} / {{ $t.createAccount }}
    </a>
    <template v-for="({ to, title, type }, i) in links" :key="i">
      <template v-if="type === 'chat'">
        <base-button
          v-if="$feature.enableChat"
          class="duration hover:c-grey-60"
          @click="handleChatClick(title)"
        >
          {{ title }}
        </base-button>
      </template>
      <base-link v-else :to class="duration hover:c-grey-60" @click="handleClick(title)">
        {{ title }}
      </base-link>
    </template>
    <base-button v-if="auth.loggedIn" class="duration hover:c-grey-60" @click="handleSignOutClick">
      {{ $t.signOut }}
    </base-button>
  </nav>
</template>

<script lang="ts" setup>
import type { NavigationLink } from '#types/navigation'

defineProps<{
  links: NavigationLink[]
}>()

const emit = defineEmits<{
  /**
   * Emits when a navigation item is clicked
   */
  click: []
}>()

const auth = useAuthStore()
const { ModalSignIn } = useDialogsStore()
const { $chat, $gtm, $t } = useNuxtApp()

const handleClick = (title: string) => {
  emit('click')
  $gtm.push('topNav.onClickUtilityLink', fullCapitalize(title))
}

const handleChatClick = (title: string) => {
  handleClick(title)
  $chat.open()
}

const handleSignInClick = async () => {
  handleClick(`${$t.signIn} / ${$t.createAccount}`)

  /**
   * Waits one tick so the OTW Mega Menu unlocks the body scroll.
   * If we remove this nextTick, sometimes when the user authenticates and
   * the modal closes the <body> keeps locked and with the style 'overflow:hidden'
   */
  await nextTick()
  ModalSignIn.open()
}

const handleSignOutClick = () => {
  handleClick($t.signOut)
  auth.logout()
}
</script>
