<template>
  <div ref="wrapperRef" class="flex">
    <base-button
      :aria-label="$t.searchIconLabel"
      :aria-expanded="opened"
      aria-controls="search"
      @click="opened = !opened"
    >
      <vf-icon
        :name="opened ? 'close' : 'search'"
        size="xl"
        :pos="`right -${opened ? '0.5' : '0.325'}rem top 0`"
        :class="{ 'c-white': invert || opened }"
      />
    </base-button>
    <transition
      enter-from-class="op-0 backdrop-op-0"
      enter-active-class="ease-out"
      leave-active-class="ease-in"
      leave-to-class="op-0 backdrop-op-0"
    >
      <div
        v-show="opened"
        id="search"
        class="fixed-0 backdrop-blur backdrop-brightness-40 backdrop-op-100 transition container -z-1"
        style="padding-top: 4.25rem;"
      >
        <!-- Search form -->
        <form role="search" class="h-full f-col" @submit.prevent="submit">
          <div class="flex b-b b-white c-white">
            <div class="flex grow">
              <base-input
                ref="inputRef"
                v-model="searchValue"
                type="text"
                :placeholder="$t.searchPlaceholder"
                :aria-label="$t.searchLabel"
                class="bg-transparent pb-4 pt-2 placeholder-white"
              />
            </div>
            <base-button type="submit" class="py-2" :aria-label="$t.searchLabel">
              <vf-icon name="search" size="xl" />
            </base-button>
          </div>
          <!-- Search suggestion box -->
          <transition name="fade">
            <section
              v-if="showSuggestions"
              :aria-label="$t.searchResults"
              class="scrollbar-none grow overflow-y-auto pt-6"
            >
              <ul
                v-if="suggestions?.products?.length"
                class="grid cols-2 gap-x-1 gap-y-6 pb-6 lg:cols-4 md:cols-3"
              >
                <li
                  v-for="{ id, name, images, currency, price, url } in suggestions?.products"
                  :key="id"
                  class="relative"
                >
                  <base-picture
                    v-if="images"
                    :src="getImageTransformations(images, { ...mediaSize, align: 'north_east' }) || defaultImageUrl"
                    :alt="name"
                    :width="mediaSize.width"
                    :height="mediaSize.height"
                    class="w-full"
                  />
                  <div class="mt-5 c-white">
                    <p class="line-clamp-2 mb-2">
                      {{ name }}
                    </p>
                    <product-pricing :price :currency class="children:!c-white" />
                  </div>
                  <base-link
                    :to="{ path: url, state: { suggestedPhrase: searchValue } }"
                    :aria-label="name"
                    class="absolute-0"
                    @click="close"
                  />
                </li>
              </ul>
              <p v-else class="pt-10 text-center c-white">
                {{ $t.noResults }}
              </p>
            </section>
          </transition>
        </form>
      </div>
    </transition>
  </div>
</template>

<script lang="ts" setup>
import { useFocusTrap } from '@vueuse/integrations/useFocusTrap'
import type { BaseInput as BaseInputType } from '#components'

defineProps<{
  invert?: boolean
}>()

const { searchThreshold } = useAppConfig().components.vf.search || {}
const { card: { mediaSize }, defaultImageUrl } = useAppConfig().components.product
const { lock, unlock } = useBodyScroll()
const router = useRouter()

const wrapperRef = ref<HTMLDivElement>()
const inputRef = ref<InstanceType<typeof BaseInputType>>()

const searchValue = ref('')
const opened = defineModel<boolean>()
const showSuggestions = ref(false)
const suggestionsQuery = reactive({ q: searchValue.value })
let abortSuggestions = false

const close = () => {
  opened.value = false
  showSuggestions.value = false
}

const handleOpenSuggestions = () => {
  if (!searchThreshold || searchValue.value.length >= searchThreshold) showSuggestions.value = true
}

const { activate, deactivate } = useFocusTrap(wrapperRef, {
  checkCanFocusTrap: () => wait(250),
  clickOutsideDeactivates: true,
  onActivate: () => nextTick(() => inputRef.value?.$el.focus()),
  onDeactivate: close
})

const {
  data: suggestions,
  pending: suggestionsPending
} = useApi().products.autoSuggest(suggestionsQuery, {
  immediate: false,
  server: false,
  onResponse() {
    showSuggestions.value = !abortSuggestions
  }
})

const submit = () => {
  if (searchValue.value.trim()) {
    abortSuggestions = suggestionsPending.value
    router.push(useLocalisedRoute(`/search?q=${searchValue.value.trim()}`))
    close()
  }
}

const onInput = useDebounceFn(() => {
  // hide suggestions if input length is below a threshold
  if (!searchThreshold || searchValue.value.length < searchThreshold) {
    showSuggestions.value = false
    return
  }

  // show previously fetched suggestions if the same search is re-entered
  if (searchValue.value === suggestionsQuery.q && !!suggestions) {
    showSuggestions.value = true
    return
  }
  // reset abort boolean when making a new search
  abortSuggestions = false
  suggestionsQuery.q = searchValue.value
})

watch(opened, () => {
  if (opened.value) {
    activate()
    lock()
    handleOpenSuggestions()
  }
  else {
    deactivate()
    unlock()
  }
})

watch(searchValue, onInput)

// close suggestions if route changes
router.beforeEach(close)
</script>
