<template>
  <div ref="wrapperRef" class="flex">
    <base-button
      :aria-label="$t.menuIconLabel"
      :aria-expanded="opened"
      aria-controls="menu"
      @click="opened = !opened"
    >
      <vf-icon
        :name="opened ? 'close' : 'menu'"
        size="xl"
        :pos="`left -${opened ? '0.5' : '0.25'}rem top 0`"
        :class="{ 'c-white': invert || opened }"
      />
    </base-button>
    <transition
      enter-from-class="op-0 backdrop-op-0"
      enter-active-class="ease-out"
      leave-active-class="ease-in"
      leave-to-class="op-0 backdrop-op-0"
      @after-leave="newsletterRef?.reset()"
    >
      <div
        v-show="opened"
        id="menu"
        class="fixed inset-y-0 left-0 w-full f-col c-white backdrop-blur backdrop-brightness-40 backdrop-op-100 duration -z-1 lg:w-98 md:w-80"
        style="padding-top: 4.25rem;"
        data-test-id="vf-mega-menu"
      >
        <div class="scrollbar-none w-full f-col grow overflow-y-auto pb-6 container" style="padding-top: 1.75rem;">
          <ul v-if="megaMenu" class="space-y-3">
            <li v-for="{ id, target, title } in megaMenu.children" :key="id">
              <base-link
                :to="target?.url"
                :target="target?.targetAttribute"
                class="text-xl duration title-1 hover:c-grey-60"
                @click="$gtm.push('topNav.onClickTopNavLink', fullCapitalize(title))"
              >
                {{ title }}
              </base-link>
            </li>
          </ul>
          <vf-utility-navigation
            v-if="utilityLinks"
            :links="utilityLinks"
            class="mt-8 f-col grow items-start gap-3"
            @click="close"
          />
          <form-newsletter ref="newsletterRef" class="mt-6" />
          <p class="mt-5 text-xs">
            {{ $t.copyright }}
          </p>
        </div>
        <base-link
          :to="vansSite[$locale]"
          target="_self"
          class="h-14 w-full flex shrink-0 items-center bg-black container between"
        >
          <span class="fw-medium">{{ $t.visitVans }}</span>
          <img :src="`${'/img/logos/vans/default.svg'}`" alt="" width="42" height="17" class="invert">
        </base-link>
      </div>
    </transition>

    <panel-location-selector class-content="b-l b-grey-60 shadow-none" hide-backdrop />
  </div>
</template>

<script lang="ts" setup>
import { useFocusTrap } from '@vueuse/integrations/useFocusTrap'

import type { MenuData } from '#root/api/clients/content/data-contracts'
import type { GetUtilityData } from '#root/api/clients/internal/data-contracts'
import type { FormNewsletter as FormNewsletterType } from '#components'

defineProps<{
  megaMenu?: MenuData['content']
  utilityLinks: GetUtilityData | null
  invert?: boolean
}>()

const PanelLocationSelector = createPanel('location-selector')
const { lock, unlock } = useBodyScroll()
const { $t, $gtm } = useNuxtApp()

const opened = defineModel<boolean>({ required: true })
const wrapperRef = ref<HTMLDivElement>()
const newsletterRef = ref<InstanceType<typeof FormNewsletterType>>()

const close = () => opened.value = false

const { activate, deactivate } = useFocusTrap(wrapperRef, {
  checkCanFocusTrap: () => wait(250),
  clickOutsideDeactivates: true,
  initialFocus: false,
  onDeactivate: close
})

watch(opened, () => {
  if (opened.value) {
    activate()
    lock()
  }
  else {
    deactivate()
    unlock()
  }

  $gtm.push('topNav.onClickHamburgerMenu', opened.value)
})

const vansSite = {
  'en-ca': 'https://www.vans.ca/en-ca',
  'en-us': 'https://www.vans.com/en-us',
  'fr-ca': 'https://www.vans.ca/fr-ca'
}
</script>
