<template>
  <base-form
    ref="formRef"
    :form="form"
    name="newsletter"
    data-test-id="form-newsletter"
    @submit="submit"
  >
    <p v-if="notification.type === 'success'" class="c-green-90">
      {{ notification.message }}
    </p>
    <base-form-field
      v-if="notification.type !== 'success'"
      v-slot="{ messages: { emailRequired, emailInvalid, policyRequired } }"
      name="email"
      :rule="newsletterValidation"
    >
      <vf-form-error
        v-if="emailRequired || emailInvalid || policyRequired || notification.type === 'error'"
        variant="inverse"
      >
        {{ emailRequired || emailInvalid || policyRequired || notification.message }}
      </vf-form-error>
      <div class="grid cols-[1fr_auto] gap-x-2">
        <base-input
          v-model="form.email"
          type="email"
          :placeholder="`${$t.newsletterPlaceholder} *`"
          :disabled="loading"
          class="bg-transparent pb-4 pt-2 placeholder-white"
          required
        />
        <vf-checkbox
          v-model="form.policy"
          size="sm"
          :disabled="loading"
          :invalid="!!policyRequired"
          variant="inverse"
          class="col-span-2 items-center b-t b-white pt-2"
          required
        >
          <form-legal-disclaimer
            :content="$t.newsletterPolicy"
            :link-classes="{ 'privacy': 'hover:c-grey-60', 'brand-terms': 'hover:c-grey-60' }"
          />
        </vf-checkbox>
        <base-button type="submit" :disabled="loading" class="col-start-2 row-start-1 pb-4 pt-2 fw-medium">
          {{ $t.newsletterSubmit }}
        </base-button>
      </div>
    </base-form-field>
  </base-form>
</template>

<script lang="ts" setup>
import type { FormLocation } from '#types/gtm'
import type { BaseNotification } from '#types/notification'
import type { Validator } from '#core/utils/validators'
import type { BaseForm as BaseFormType } from '#components'

const { formLocation = 'inline:footer:none' } = defineProps<{
  formLocation?: FormLocation
}>()

const { $t, $gtm } = useNuxtApp()
const subscriptions = useSubscriptions()
const { validateRequired, validateEmail } = useLocalizedValidators()

const formRef = ref<InstanceType<typeof BaseFormType>>()
const form = reactive({
  email: '',
  policy: false
})
const notification = reactive<BaseNotification>({
  type: '',
  message: ''
})
const loading = ref(false)

const newsletterValidation: Record<string, Validator> = {
  emailRequired: validateRequired($t.newsletterForm.emailRequired),
  emailInvalid: validateEmail($t.newsletterForm.emailRequired),
  policyRequired: (_, data) => !data?.policy && $t.newsletterForm.policyRequired
}

const submit = async () => {
  try {
    loading.value = true
    notification.type = ''
    $gtm.push('newsletterForm.onSubmit', formLocation)
    await subscriptions.newsletter({ optIn: true, email: form.email, acquisitionType: 'Signup' })

    form.policy = false
    form.email = ''

    notification.type = 'success'
    notification.message = $t.newsletterSuccess

    $gtm.push('newsletterForm.onConfirmed', formLocation)
  }
  catch (e) {
    if (e instanceof Error) {
      notification.type = 'error'
      notification.message = e.message
    }
  }
  finally {
    loading.value = false
  }
}

onMounted(() => $gtm.push('newsletterForm.onImpression', formLocation))

defineExpose({
  reset: () => {
    form.email = ''
    form.policy = false
    formRef.value?.reset()
  }
})
</script>
